<template>
  <div class="direct-flight-select justify-content-start justify-content-md-end mr-md-50 pb-25">
    <!-- :disabled="isSearchClassBooking" -->
    <b-form-checkbox
      v-model="isDirectFlightOnly"
      switch
      class="custom-control-warning pb-50"
      @input="val => handleUpdate(val)"
    >
      <span class="text-body font-weight-bold">{{ $t('flight.directFlightSelect') }}</span>
    </b-form-checkbox>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'
import {
  ref, watch,
} from '@vue/composition-api'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    numberOfStop: {
      type: Number,
      default: () => 0,
    },
    isSearchClassBooking: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isDirectFlightOnly = ref(false)

    watch(() => props.numberOfStop, val => {
      if (val === 0) {
        isDirectFlightOnly.value = true
      } else {
        isDirectFlightOnly.value = false
      }
    }, { immediate: true })

    const handleUpdate = val => {
      if (val === false) {
        emit('update:numberOfStop', 99)
      } else {
        emit('update:numberOfStop', 0)
      }
    }

    return {
      isDirectFlightOnly,
      handleUpdate,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .direct-flight-select{
    margin-bottom: -7px !important;
  }

  </style>
